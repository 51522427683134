const localeOptions = [
    { key: 'da-DK', text: 'Danish' },
    { key: 'nl-NL', text: 'Dutch' },
    { key: 'en-US', text: 'English' },
    { key: 'fi-FI', text: 'Finnish' },
    { key: 'fr-FR', text: 'French' },
    { key: 'de-DE', text: 'German' },
    { key: 'pl-PL', text: 'Polish' },
    { key: 'es-ES', text: 'Spanish' },
    { key: 'sv-SE', text: 'Swedish' },
];

export default localeOptions;