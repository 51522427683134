import React, { useMemo, useCallback, useState, useRef, useEffect } from 'react';
import ReactWebChat, { createDirectLine, createStore } from 'botframework-webchat';
import CustomHeroCardContent from './Renderer/CustomHeroCardContent';
import { MicModeButton } from './MicModeButton';
import { Notification } from './Notification';
import { LanguageMenu } from './LanguageMenu';


export default function App(props) {

	let coreStyleOptions = useMemo(() => (
		{
			avatarSize: 37,
			botAvatarImage: props.inConversationIconUrl ? props.inConversationIconUrl : 'richard.svg',
			botAvatarInitials: '',
			userAvatarBackgroundColor: 'transparent',
			botAvatarBackgroundColor: 'transparent',
			backgroundColor: '#F6F6F6',
			bubbleBackground: '#FFFFFF',
			bubbleBorderWidth: 0,
			bubbleFromUserBorderWidth: 0,
			bubbleNubSize: 8,
			bubbleNubOffset: 'top',
			hideUploadButton: !props.showUpload,
			primaryFont: '\'ABB Voice\', sans-serif',
			fontSizeSmall: '10px',
			showAvatarInGroup: true,
			hideScrollToEndButton: !props.showNewMessagesButton,
			transcriptActivityVisualKeyboardIndicatorWidth: 0,
			transcriptVisualKeyboardIndicatorWidth: 0,
			paddingRegular: 12
		}
	), [props.inConversationIconUrl, props.showUpload, props.showNewMessagesButton]);

	let customBrandingStyleOptions = useMemo(() => {
		const abbDarkerRed = '#E8140E';
		const abbLighterRed = '#FF000F';
		return {
			bubbleBorderRadius: 16,
			bubbleFromUserBackground: abbDarkerRed,
			bubbleFromUserBorderRadius: 16,
			bubbleFromUserTextColor: 'white',
			bubbleFromUserNubOffset: 'bottom',
			bubbleFromUserNubSize: 0,
			bubbleMaxWidth: 370,
			paddingRegular: 12,
			suggestedActionBorderRadius: 22,
			suggestedActionBorderWidth: 1,
			suggestedActionTextColor: abbLighterRed,
			suggestedActionBorderColor: abbLighterRed,
			suggestedActionBackgroundColorOnHover: abbDarkerRed,
			suggestedActionBorderColorOnHover: abbDarkerRed,
			suggestedActionTextColorOnHover: 'white',
			suggestedActionLayout: 'flow',
			suggestedActionsStackedLayoutButtonTextWrap: true,
			suggestedActionsStackedHeight: 250,
			sendBoxButtonAlignment: 'top'
		}
	}, []);

	let defaultStyleOptions = useMemo(() => (
		{
			bubbleBorderRadius: 4,
			bubbleFromUserBackground: '#f0f0f0',
			bubbleFromUserBorderRadius: 4,
			bubbleFromUserNubOffset: 'top',
			bubbleFromUserNubSize: 8,
		}
	), []);

	const {
		hooks: { usePostActivity }
	} = window.WebChat;
	const errorShown = useRef(null);
	const attachmentUploadFileNames = useRef(null);
	attachmentUploadFileNames.current = [];
	const BotActivityDecorator = ({ activityId, promptText, children }) => {
		const postActivity = usePostActivity();

		const [feedback, setFeedback] = useState(0);
		const sendFeedback = useCallback((newFeedback) => {
			postActivity({
				type: 'event',
				valueType: 'feedback',
				value: { activityId, reactionValue: newFeedback }
			});
			setFeedback(newFeedback);
		}, [activityId, setFeedback, postActivity]);

		const handleDownvoteButton = useCallback(() => {
			sendFeedback(-1);
		}, [sendFeedback]);

		const handleUpvoteButton = useCallback(() => {
			sendFeedback(1);
		}, [sendFeedback]);

		const getUpvoteButtonClassName = () => {
			switch (feedback) {
				case 1:
					return "botActivityDecorator__buttonChosenGreen";
				case -1:
					return "botActivityDecorator__buttonNotChosenGreen";
				default:
					return "botActivityDecorator__buttonGreen";
			}
		};

		const getDownvoteButtonClassName = () => {
			switch (feedback) {
				case -1:
					return "botActivityDecorator__buttonChosenRed";
				case 1:
					return "botActivityDecorator__buttonNotChosenRed";
				default:
					return "botActivityDecorator__buttonRed";
			}
		};

		return (
			<div className="botActivityDecorator">
				<div className="botActivityDecorator__content">{children}</div>
				<div className="botActivityDecorator__buttonBar">
					<span className="botActivityDecorator__label">{promptText} </span>
					<button className={getUpvoteButtonClassName()} onClick={handleUpvoteButton} disabled={feedback !== 0}>
						<img src='vote.svg' alt='upvote' />
					</button>
					<button className={getDownvoteButtonClassName()} onClick={handleDownvoteButton} disabled={feedback !== 0}>
						<img src='vote.svg' alt='downvote' />
					</button>
				</div>
			</div>
		);
	};

	function getScheme(href) {
		href = href.replace(/[\x00-\x20]+/gu, '');
		href = href.replace(/<!--.*?-->/gu, '');
		const matches = href.match(/^([a-zA-Z]+):/u);
		if (!matches) {
			return;
		}

		return matches[1].toLowerCase();
	}

	const ALLOWED_SCHEMES = ['data', 'http', 'https', 'ftp', 'mailto', 'sip', 'tel', 'msteams'];

	const cardActionMiddleware = () => next => (...args) => {
		const [
			{
				cardAction: { type, value },
				getSignInUrl
			}
		] = args;

		switch (type) {
			case 'call':
			case 'downloadFile':
			case 'openUrl':
			case 'playAudio':
			case 'playVideo':
			case 'showImage':
				if (ALLOWED_SCHEMES.includes(getScheme(value))) {
					window.open(value, '_blank', 'noopener noreferrer');
				} else {
					console.warn('botframework-webchat: Cannot open URL with disallowed schemes.', value);
				}

				break;

			case 'signin': {
				(async function () {
					const popup = window.open();
					const url = await getSignInUrl();

					popup.location.href = url;
				})();

				break;
			}

			default:
				return next(...args);
		}
	}

	const activityMiddleware = () => next => (...setupArgs) => {
		const [card] = setupArgs
		if (card.activity.from.role === 'bot' && Boolean(card.activity.feedbackPromptText)) {
			if (errorShown.current) {

				errorShown.current = false;
				hideAttachmentTooBigError();
			}
			return (...renderArgs) => (
				<BotActivityDecorator key={card.activity.id}
					activityId={card.activity.replyToId}
					promptText={card.activity.feedbackPromptText}>
					{next(card)(...renderArgs)}
				</BotActivityDecorator>
			);
		}
		else if (card.activity.attachments && card.activity.channelData) {
			if (card.activity.channelData.attachmentSizes && card.activity.channelData.attachmentSizes.some(size => size > 4193255)) {
				if (!errorShown.current) {
					let currentFiles = card.activity.attachments.map(a => a.name);

					if (attachmentUploadFileNames.current.some(file => currentFiles.includes(file[0]))) {
						return;
					}
					errorShown.current = true;
					attachmentUploadFileNames.current.push(currentFiles);
					displayAttachmentTooBigError();
				}
				return;
			}
			else if (card.activity.channelData.attachmentSizes) {
				hideAttachmentTooBigError();
				errorShown.current = false;
			}
		}
		return next(...setupArgs)
	};

	const displayAttachmentTooBigError = () => {
		var msg = document.createElement("p");
		var content = document.createTextNode("Attachments are too big.");
		msg.appendChild(content);
		msg.className = "userAttachmentError";
		var el = document.querySelector('[role="form"]');
		el.prepend(msg);
	}

	const hideAttachmentTooBigError = () => {

		var el = document.querySelector('[role="form"]');
		if (el.firstChild.className === "userAttachmentError") {
			el.removeChild(el.firstChild);
		}
	}

	let token = props.token;
	let userId = props.userId;
	let locale = props.locale;
	let supportedLocales = props.supportedLocales;
	let disableOldButtons = props.disableOldButtons;
	let eventParams = useMemo(() => {
		return props.eventParams;
	}, [props.eventParams]);
	let sapCustomStyling = props.sapCustomStyling;

	const getStyleOptions = useCallback((customStyling) => {
		return customStyling
			? { ...coreStyleOptions, ...customBrandingStyleOptions }
			: { ...coreStyleOptions, ...defaultStyleOptions };
	}, [coreStyleOptions, customBrandingStyleOptions, defaultStyleOptions]);

	const [directLine, setDirectLine] = useState(createDirectLine({}));
	const [store, setStore] = useState();
	const [styleOptions, setStyleOptions] = useState();
	const initConversation = useCallback(() => {
		setStore(eventParams ? createStore({}, ({ dispatch }) => next => action => {
			if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
				dispatch({
					type: 'WEB_CHAT/SEND_EVENT',
					payload: {
						name: 'webchatData',
						value: eventParams
					}
				});
					// if(sapCustomStyling){
					// 	dispatch({
					// 		type: 'WEB_CHAT/SET_SEND_BOX',
					// 		payload: {
					// 		  text: 'Hello World'
					// 		}}
					// 	);
					// }
				
		
				 
			}
			return next(action);
		}): null)
		setDirectLine(createDirectLine({ token }));
		setStyleOptions(getStyleOptions(props.customStyling))
	}, [eventParams, token, props.customStyling, getStyleOptions, sapCustomStyling]);

	useEffect(() => {
		document.title = props.chatName;
		initConversation();
	}, [initConversation, props.chatName]);

	const attachmentMiddleware = () => next => card => {
		const { activities } = store.getState();
		const messageActivities = activities.filter(activity => activity.type === 'message');
		const lastUserMessageIndex = messageActivities.map(x => x.from.role).lastIndexOf('user');
		const cardActivityIndex = messageActivities.map(x => x.id === card.activity.id).indexOf(true);
		const recentBotMessage = cardActivityIndex > lastUserMessageIndex;
		const disabled = !recentBotMessage && disableOldButtons;
		const sapCustomStyling = props.sapCustomStyling;

		switch (card.attachment.contentType) {
			case 'application/vnd.microsoft.card.hero':
				return (
					<CustomHeroCardContent content={card.attachment.content}
						disabled={disabled}
						actionPerformedClassName={disabled ? 'buttondisabled' : ''} />
				);
			default:
				return next(card, !recentBotMessage);
		}
	}

	let micModeEnabled = props.ponyFillAdapter ? true : false;
	const [showMic, setShowMic] = useState(false);
	let adapter = showMic ? props.ponyFillAdapter : null;

	const isMultilanguage = supportedLocales ? true : false;
	const [currentLocale, setCurrentLocale] = useState(locale);

	async function handleMicModeEnabled(e) {
		let isAudioGranted = await isAudioPermissionGranted();
		if (isAudioGranted) {
			setShowMic(true)
			initConversation();
		} else {
			alert('You denied access to microphone, please change microphone settings in your browser for this page if you want to use microphone.')
		}
	}


	useEffect(() => {
		if (showMic && isMultilanguage) {
			let mainSendbox = document.querySelector('div.webchat__send-box__main');
			mainSendbox.className += " multiLanguageSendbox";
		}	
	}, [showMic, isMultilanguage]);

	const handleLocaleChange = (newLocale) => {
		setCurrentLocale(newLocale);
	}


	return (
		<>
			<ReactWebChat webSpeechPonyfillFactory={adapter} cardActionMiddleware={cardActionMiddleware}
				activityMiddleware={activityMiddleware} directLine={directLine} locale={currentLocale} userId={userId} styleOptions={styleOptions}
				attachmentMiddleware={attachmentMiddleware} store={store}/>;n
			{(micModeEnabled && !showMic) && <MicModeButton enableMicMode={handleMicModeEnabled} />}
			{showMic && <Notification currentLocale={currentLocale} isMultilanguage={isMultilanguage} />}
			{(showMic && isMultilanguage) && <LanguageMenu locales={supportedLocales}
				currentLocale={currentLocale} onLocaleChange={handleLocaleChange} />}
		</>)
}

async function isAudioPermissionGranted() {
	try {
		await navigator.mediaDevices.getUserMedia({ audio: true });
		return true;
	} catch (err) {
		console.error(err);
		return false;
	}
}

