import { useState } from "react"
import localeOptions from "./LocaleOptions";

export function getLanguage(locale) {
    return localeOptions.find(option => option.key.toLowerCase() === locale).text;
}

export const LanguageMenu = (props) => {
    const { locales, onMenuHovered, currentLocale, ...otherLanguageItemProps } = props;
    const [menuOpened, setMenuOpened] = useState(false);

    const handleOnMouseEnter = () => setMenuOpened(true);

    const handleOnMouseLeave = () => setMenuOpened(false);

    return (
        <>
            <button id='currenLanguageBox' onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
                {getLanguage(currentLocale)}
                {menuOpened ? <img src='down.svg' alt='down' /> : <img src='up.svg' alt='up' />}
            </button>
            {menuOpened &&
                <ul id='languagesMenu' onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
                    {
                        props.locales.map(locale => {
                            return (
                                <LanguageItem key={locale} locale={locale} currentLocale={currentLocale}
                                    {...otherLanguageItemProps} />
                            )
                        })
                    }
                </ul>
            }
        </>
    )
}

const LanguageItem = ({ locale, currentLocale, onLocaleChange }) => {
    const [isHovered, setIsHovered] = useState(false);

    const LocaleItemStyle = {
        background: isHovered ? '#f6f6f6' : 'inherit',
        color: locale === currentLocale ? 'black' : '#bebebe'
    }

    const handleClick = () => onLocaleChange(locale);

    const handleOnMouseEnter = () => setIsHovered(true);

    const handleOnMouseLeave = () => setIsHovered(false);

    return (
        <li key={locale}
            onClick={handleClick}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            style={LocaleItemStyle}
        >{getLanguage(locale)}</li>

    )
}