import { hooks } from 'botframework-webchat-component';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import CustomCardBuilder from './CustomCardBuilder';
import CustomCardRenderer from './CustomCardRenderer';

const { useDirection, useStyleOptions } = hooks;

const CustomHeroCardContent = ({ actionPerformedClassName, content, disabled }) => {
  const [styleOptions] = useStyleOptions();
  const [direction] = useDirection();
  const builtCard = useMemo(() => {
    const builder = new CustomCardBuilder(styleOptions, direction);

    if (content) {
      (content.images || []).forEach(image => builder.addImage(image.url, null, image.tap, image.alt));

      builder.addCommon(content);

      return builder.card;
    }
  }, [content, direction, styleOptions]);

  return (
    <CustomCardRenderer
      actionPerformedClassName={actionPerformedClassName}
      adaptiveCard={builtCard}
      disabled={disabled}
      tapAction={content && content.tap}
    />
  );
};

CustomHeroCardContent.defaultProps = {
  actionPerformedClassName: '',
  disabled: undefined
};

CustomHeroCardContent.propTypes = {
  actionPerformedClassName: PropTypes.string,
  content: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        alt: PropTypes.string,
        tap: PropTypes.any,
        url: PropTypes.string.isRequired
      })
    ),
    tap: PropTypes.any
  }).isRequired,
  disabled: PropTypes.bool
};

export default CustomHeroCardContent;