import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

function getUrlParameters() {
    var urlParams = {};
    (function () {
        var e,
            a = /\+/g,
            r = /([^&=]+)=?([^&]*)/g,
            d = function (s) {
                return decodeURIComponent(s.replace(a, " "));
            },
            q = window.location.search.substring(1);

        while (e = r.exec(q))
            urlParams[d(e[1])] = d(e[2]);
    })();
    return urlParams
};

function getLang() {
    if (navigator.languages != undefined)
        return navigator.languages[0];     
    return navigator.language;
  }

function getLocalisation(){
    if (navigator.languages != undefined){
        if(navigator.languages[0] === 'nl'){
            return 'nl-NL';
        }
        return navigator.languages[0]; 
    }
    return navigator.language;
}

async function fetchResponse(guid) {
    if (guid === null || guid === '') return null;

    var baseUrl = process.env.REACT_APP_BASE_URL;
    var url = baseUrl + guid;
    let textResponse = null;

    try {
        const response = await fetch(url, { method: 'GET' });
        if (response.ok === false) {
            throw new Error(`HTTP Error: ${response.status}`);
        }
        textResponse = await response.text();
    } catch (error) {
        console.error(error);
    }
    return textResponse;
}

async function createHybridPonyfillFactory(webchat) {
    const webSpeechPonyfillFactory = await webchat.createBrowserWebSpeechPonyfillFactory();
    return options => {
        const webSpeechPonyfill = webSpeechPonyfillFactory(options);
        return {
            SpeechGrammarList: webSpeechPonyfill.SpeechGrammarList,
            SpeechRecognition: webSpeechPonyfill.SpeechRecognition,
            speechSynthesis: null,
            SpeechSynthesisUtterance: null
        }
    };
}

async function checkIfIsImage(url) {
    return new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute('src', url);
        img.addEventListener('error', (e) => reject(e));
        img.addEventListener('load', () => resolve());
    })
}

async function init(guid, params) {
    var response = await fetchResponse(guid);
    var preferredLanguage = getLang();
    params = {...params, preferredLanguage};
    if (!response) {
        ReactDOM.render(<span>BotID is invalid.</span>, document.getElementById('root'));
        return;
    }

    var result = JSON.parse(response);
    var { userId, chatName, locale, headerVisible, uploadButtonVisible, newMessagesNotificationEnabled,
        speechToTextEnabled, oldButtonsEnabled, supportedLocales, inConversationIconUrl, customStyling, sapCustomStyling } = result;

    if (customStyling) {
        import("./customStyling.css");
    }

    if(sapCustomStyling){
        import("./sapCustomStyling.css");
        locale = getLocalisation();
    }

    var adapter = speechToTextEnabled ? await createHybridPonyfillFactory(window.WebChat) : null;

    try {
        if (inConversationIconUrl) {
            await checkIfIsImage(inConversationIconUrl);
        }
    } catch (error) {
        console.error("Provided invalid custom image url:", inConversationIconUrl);
        inConversationIconUrl = null;
    }
    
    ReactDOM.render(
        <App userID={userId} token={result.chatToken.token} ponyFillAdapter={adapter}
            showUpload={uploadButtonVisible} locale={locale} supportedLocales={supportedLocales}
            showNewMessagesButton={newMessagesNotificationEnabled}
            disableOldButtons={oldButtonsEnabled}
            chatName={chatName}
            eventParams={params}
            inConversationIconUrl={inConversationIconUrl}
            customStyling={customStyling}
            sapCustomStyling={sapCustomStyling}
        />, document.getElementById('root'));

    if (headerVisible) {
        ReactDOM.render(<span className="bot-bar">{chatName}</span>, document.getElementById("bar"));
        document.getElementById("root").className = "with-bar";
    }
    document.querySelector('#root > *').focus();
}

//redirectToProperUrl();

let urlParams = getUrlParameters();
const { id, ...params } = urlParams;
let guid = id ? id : '';

init(guid, params);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

function redirectToProperUrl() {

    const currentOrigin = window.location.origin;
    const searchPattern = window.location.search;

    const urlToRedirect = 'https://dev.chatbots.abb.com';
    // const urlToRedirect = 'https://chatbots.abb.com';
    // const urlToRedirect = 'http://localhost:3000';

    const redirectTo = `https://chatbots.abb.com/${searchPattern}`;
    // const redirectTo = `https://dev.chatbots.abb.com/${searchPattern}`;
    // console.log("curent", currentOrigin, urlToRedirect)

    if (currentOrigin === urlToRedirect) {
        window.location.assign(redirectTo);
    }
}