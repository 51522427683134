import { useEffect, useState } from "react"
import { getLanguage } from "./LanguageMenu";

export const Notification = ({ currentLocale, isMultilanguage }) => {
    const [isShown, setIsShown] = useState(true);
    const multilanguageMessage = ` Your bot now detects ${getLanguage(currentLocale)} language.`;

    useEffect(() => {
        setTimeout(() => {
            setIsShown(false);
        }, 5000)
    }, []);

    if (!isShown) {
        return null;
    }

    if (isShown) {
        return (
            <div id='notificationContainer'>
                <p>You have enabled microphone mode. Click microphone icon to speak to the bot.{isMultilanguage ? multilanguageMessage : null}</p>
            </div>
        )
    }

}